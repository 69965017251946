
/*********** BEGIN ORIGINAL STYLES *********/
.clear {
   clear: both;
}
.letterGroup {
   height: 335px;
   overflow: auto;
   border: 1px solid #fff;
   float: left;
   margin: 5px;
   min-width: 200px;
   padding: 3px 5px;
}
.letter {
   font-weight: bold;
}
.animal {
   margin: 2px 0px 2px 15px;
}

body {
   background-color: rgba(12, 29, 234, 0.15);
}

@font-face {
   font-family: 'FugazOne-Regular';
   src:url("https://www.mikewillisphotography.com/include/fonts/FugazOne-Regular.ttf") format('truetype');
}
/*********** END ORIGINAL STYLES *********/

@media( min-width: 300px ) {
   /* mobile and up */
   .ui.container {
      width: 100%;
   }

   .header-banner {
      background: url('/images/banner.jpg') no-repeat -644px 0px;
      background-size: cover;
      padding: 2em 0 0;
   }

   a.lightbox-ShowPhotoData {
      position: absolute;
      top: 10px;
      left: 30px;
      width: auto;
      z-index: 9999;
   }
   .lightbox-PhotoData {
      display: none;
      padding: 3px;
      margin: 0px;
      border: 1px solid #fff;
      color: #fff;
      font-size: 1em;
      background-color: rgba(0, 0, 0, 0.75);
      background: rgba(0, 0, 0, 0.75);
   }
   .lightbox-PhotoData ul {
      padding: 0px;
      list-style-type: none;
   }
   .lightbox-PhotoData li {
      padding: 0px;
      white-space: nowrap;
   }
   .textContainer {
      position: relative;
   }
   .photoBlurb p span {
      font-weight: bold;
      font-size: 1.5em;
      font-family: 'FugazOne-Regular';
   }

   .photoBlurbA { top: 35%; left: 10%; }
   .photoBlurbB { top: 55%; left: 55%; }
   .photoBlurbC { top: 15%; left: 65%; }
   .photoBlurbD { top: 30%; left: 30%; }

   .photoBlurb {
      display: block;
      position: initial;
      float: left;
      height: 300px;
      width: 46%;
      border-width: 1%;
      margin-left: 1%;
   }

   .photoBlurb p {
      display: block;
      height: 100%;
      width: 100%;
      position: initial;
   }

   .footer {
      width: 100%;
      padding: 20px;
      text-align: center;
      background-color: #ff9541;
   }

   body {
      font-size: 1.1em;
   }

   .content {
      padding: 3px;
   }

   /* mobile and up */
} /* min-width: 300px */

@media( min-width: 767px ) {
   /* tablet and up */
   .photoBlurb {
      text-align: center;
      position: absolute;
      height: 30px;
      width: 30px;
      border: 1px solid #000;
      display: none;
      background-color: #fff;
   }

   .photoBlurb p {
      display: none;
      position: absolute;
      top: -1px;
      left: -1px;
      height: auto;
      width: 400px;
      background-color: #fff;
      overflow: hidden;
      padding: 5px;
      border: 1px solid #000;
      margin: 0px;
   }
   /* tablet and up */
} /* min-width: 767px */

@media( min-width: 991px ) {
   /* desktop and up */

   /* desktop and up */
} /* min-width: 991px */

@media( min-width: 1200px ) {
   /* lark desktop and up */

   /* lark desktop and up */
} /* min-width: 1200px */

.dropdown-menu {
   display: block;
   visibility: hidden;
   opacity: 0;
   transition: visibility 0.25s, opacity 0.25s linear;
}

.banner-header {
   background-image: url("https://www.mikewillisphotography.com/images/banners/r.php");
}