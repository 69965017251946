@media( min-width: 300px ) {
   /* mobile and up */
   .myHeader {
      padding: 0px 3% !important;
      width: 100%;
      margin-bottom: 0px !important;
   }
   .myHeader > .ui.text.menu {
      margin: 0px !important;
      position: relative;
   }

   .myHeader, .myHeader .ui.menu {
      font-family: "FugazOne-Regular" !important;
      font-size: 1.1em;
   }

   .myHeader .ui.menu .text.item > *, .myHeader .ui.menu .item > a:not(.ui), .myHeader .ui.menu .item > p:only-child {
      padding: 0.78571429em 1.14285714em !important;
      display: inline-block !important;
   }

   .myHeader .ui.menu .ui.dropdown .menu > .item {
      padding: 0px !important;
   }
   .myHeader .ui.text.menu .item {
      padding-left: 3px;
      padding-right: 3px;
   }

   .myHeader .ui.menu:not(.vertical) .right.item:not(.icon),
   .myHeader .ui.menu:not(.vertical) .right.menu:not(.icon) {
      position: absolute;
      display: none !important;
      margin-right: 15px !important;
   }
   .myHeader .ui.menu:not(.vertical) .right.showMenu.item:not(.icon),
   .myHeader .ui.menu:not(.vertical) .right.showMenu.menu:not(.icon) {
      display: block !important;
      position: absolute;
      top: 35px;
      right: 10px;
      z-index: 1;
      background-color: #fff;
   }



   .myHeader .ui.text.menu .item .divider.text {
      padding: 0.78571429em 1.14285714em !important;
   }
   .myHeader .icon.bars {
      cursor: pointer;
      margin: 5px;
      position: absolute;
      top: 10px;
      right: 10px;
   }
   /* mobile and up */
} /* min-width: 300px */

@media( min-width: 767px ) {
   /* tablet and up */
   /* tablet and up */
} /* min-width: 767px */

@media( min-width: 830px ) {
   /* NAVIGATION BREAKPOINT: switch from hamburger to horizontal */

   .myHeader .ui.menu:not(.vertical) .right.item:not(.icon),
   .myHeader .ui.menu:not(.vertical) .right.menu:not(.icon),
   .myHeader .ui.menu:not(.vertical) .right.showMenu.item:not(.icon),
   .myHeader .ui.menu:not(.vertical) .right.showMenu.menu:not(.icon) {
      display: flex !important;
      position: relative;
      top: 0px;
      right: 0px;
   }
   .myHeader .ui.text.menu .item .divider.text {
      padding: 2px !important;
   }
   .myHeader .icon.bars {
      display: none;
   }
   .myHeader .ui.menu:not(.vertical) .right.showMenu.item:not(.icon),
   .myHeader .ui.menu:not(.vertical) .right.showMenu.menu:not(.icon) {
      display: flex !important;
      position: relative;
   }
   /* NAVIGATION BREAKPOINT: switch from hamburger to horizontal */
} /* min-width: 767px */

@media( min-width: 991px ) {
   /* desktop and up */
   .myHeader {
      padding: 0px 10% !important;
   }
   .myHeader .ui.text.menu .item {
      padding-left: 0.5em;
      padding-right: 0.5em;
   }
   /* desktop and up */
} /* min-width: 991px */

@media( min-width: 1200px ) {
   /* lark desktop and up */

   /* lark desktop and up */
} /* min-width: 1200px */